<template>
  <HgModal v-if="showShareModal" class="share-modal" @onClose="closeShareModal">
    <!-- :is-fullscreen="isMobile" -->
    <div class="tw-p-4 hg-modal-content">
      <h2 class="hg-title">
        {{ $t('shareModal.title') }}
      </h2>
      <p class="subtitle tw-mt-2">
        {{ $t('shareModal.subtitle') }}
      </p>
      <hr />

      <div class="social-buttons">
        <ShareNetwork
          network="facebook"
          :url="getDealLink(selectedDeal)"
          :title="selectedDeal.title"
          :description="selectedDeal.description"
          class="hg-button hg-button--teal hg-button--regular"
          @click.native="datalayerpush('facebook')"
        >
          <span>
            <HgIcon pack="fab" name="facebook" />
            <span>
              {{ $t('share.facebook') }}
            </span>
          </span>
        </ShareNetwork>
        <ShareNetwork
          network="whatsapp"
          :url="getDealLink(selectedDeal)"
          :title="selectedDeal.title"
          :description="selectedDeal.description"
          class="hg-button hg-button--teal hg-button--regular"
          @click.native="datalayerpush('whatsapp')"
        >
          <span>
            <HgIcon pack="fab" name="whatsapp" />
            <span>
              {{ $t('share.whatsapp') }}
            </span>
          </span>
        </ShareNetwork>
        <ShareNetwork
          network="pinterest"
          :url="getDealLink(selectedDeal)"
          :title="selectedDeal.title"
          :description="selectedDeal.description"
          class="hg-button hg-button--teal hg-button--regular"
          @click.native="datalayerpush('pinterest')"
        >
          <span>
            <HgIcon pack="fab" name="pinterest" />
            <span>
              {{ $t('share.pinterest') }}
            </span>
          </span>
        </ShareNetwork>
        <ShareNetwork
          network="messenger"
          :url="getDealLink(selectedDeal)"
          :title="selectedDeal.title"
          :description="selectedDeal.description"
          class="hg-button hg-button--teal hg-button--regular"
          @click.native="datalayerpush('messenger')"
        >
          <span>
            <HgIcon pack="fab" name="facebook-messenger" />
            <span>
              {{ $t('share.messenger') }}
            </span>
          </span>
        </ShareNetwork>
        <ShareNetwork
          network="twitter"
          :url="getDealLink(selectedDeal)"
          :title="selectedDeal.title"
          class="hg-button hg-button--teal hg-button--regular"
          @click.native="datalayerpush('twitter')"
        >
          <span>
            <HgIcon pack="fab" name="twitter" />
            <span>
              {{ $t('share.twitter') }}
            </span>
          </span>
        </ShareNetwork>
      </div>
    </div>
  </HgModal>
</template>

<script>
import { mapState } from 'vuex';

import DealPathName from '@/mixins/DealPathName';

export default {
  mixins: [DealPathName],
  computed: {
    ...mapState({
      showShareModal: (state) => state.showShareModal,
      selectedDeal: (state) => state.selectedDeal,
      isMobile: (state) => state.isMobile
    })
  },
  methods: {
    datalayerpush(network) {
      var location;
      if (this.$route.path === '/') {
        location = 'homepage';
      } else if (['/deals/', '/angebote/', /aanbiedingen/].includes(this.$route.path)) {
        location = 'deal_overview_page';
      } else {
        location = 'dealpage';
      }

      this.$gtm.push({
        event: 'gtmEvent',
        eventName: 'deal_shared',
        location,
        clickedElement: network
      });
    },
    getDealLink(deal) {
      if (!window) return;

      if (
        deal.__typename == 'DirectDeal' ||
        deal.__typename == 'AmadeusPackageTourDeal'
      ) {
        return deal.price.forwarderUrl;
      }

      const path = this.localePath({
        name: this.getDealPathName(deal.__typename || deal.type),
        params: { slug: deal.slug }
      });

      return window.location.host + path;
    },
    closeShareModal() {
      this.$store.commit('setShowShareModal', false);
    }
  }
};
</script>

<style scoped lang="scss">
.social-buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.social-buttons a {
  margin-top: 1rem;
}
</style>

<style>
.share-modal .hg-modal-scroll-content {
  max-width: 420px;
  pointer-events: visible;
}

.hg-modal-content {
  pointer-events: none;
}

.hg-modal-scroll-content .hg-modal-content {
  pointer-events: auto;
}
</style>
