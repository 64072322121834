export default {
  methods: {
    getDealPathName(type) {
      switch (type) {
        case 'AccommodationDeal':
          return 'deal-accommodation-slug';
        case 'TravelBasicDeal':
          return 'deal-travel-basic-slug';
        case 'FlightDeal':
          return 'deal-flight-slug';
        case 'TravelCombinationDeal':
          return 'deal-travel-combination-slug';
        case 'PackageTourDeal':
          return 'deal-package-tour-slug';
        case 'AmadeusPackageTourDeal':
          return 'deal-amadeus-package-tour-slug';
        case 'BasicDeal':
        default:
          return 'deal-basic-slug';
      }
    },
  },
};
